import { useContext, createContext, ReactNode, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IAuthContext, ILoginForm, Tenant } from './types'
import { useLogin, useLogout, useTenantInfo } from "./api";
// import Cookies from "js-cookie";
import { useOktaAuth } from '@okta/okta-react';
import { updateAuthorizationHeader } from "@/helpers/axiosClient";
import { Spinner } from "grommet";

const AuthContext = createContext<IAuthContext | null>(null);
export const COOKIE_TOKEN_KEY = "TC_AUTH"
export const COOKIE_REMEMBER_KEY = "tc-login"

const AuthProvider = ({ children }: { children: ReactNode }) => {
    const { authState, oktaAuth } = useOktaAuth();

    const [user, setUser] = useState<string>("");
    const [token, setToken] = useState<string>(authState?.isAuthenticated ? oktaAuth.getAccessToken() || "" : "");

    const navigate = useNavigate();
    const [body, setBody] = useState<ILoginForm | null>(null)
    const [errorMsg, setErrorMsg] = useState<string>("")
    const [isLoggingOut, setLoggingOut] = useState(false);
    const [tenant, setTenant] = useState<Tenant | null>(null);

    const { data: tenantData, refetch, error: tenantError, isLoading: tenantLoading } = useTenantInfo()

    const {
        isLoading,
        isError,
        error,
        isSuccess,
        data,
        mutate,
    } = useLogin(body!);

    const {
        isError: logoutIsError,
        isSuccess: logoutIsSuccess,
        mutate: mutateLogout,
    } = useLogout();

    useEffect(() => {
        let expireTime: any = localStorage.getItem("expires_in") || 0
        let remainingTime = +expireTime - new Date().getTime()

        if (authState?.isAuthenticated || remainingTime > 0) {
            updateAuthorizationHeader(oktaAuth?.getAccessToken() || "")
            refetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState])

    const ssoLogin = async () => {
        oktaAuth.signInWithRedirect({ originalUri: '/' });
    };

    const loginAction = async (body: ILoginForm) => {
        // if (body?.rememberMe && body.user_name && body.password) {
        // Cookies.set(
        //     COOKIE_REMEMBER_KEY,
        //     JSON.stringify({
        //         rememberMe: body.rememberMe,
        //         user_name: body.user_name,
        //         password: body.password,
        //     }),
        //     { expires: 30, secure: true }, // expires in 30 days
        // );
        // delete body.rememberMe
        // }
        setBody(body)
        setErrorMsg("")
    };

    useEffect(() => {
        if (authState && authState.isAuthenticated && !isLoggingOut) {
            setToken(oktaAuth.getAccessToken()!)
            oktaAuth.getUser().then((user) => { setUser(user?.email as string) })

            updateAuthorizationHeader(oktaAuth?.getAccessToken() || "")
            const expireTime = (authState.accessToken?.expiresAt || 0) * 1000
            localStorage.removeItem("expires_in")
            localStorage.setItem("expires_in", expireTime + "");
            // Cookies.set(COOKIE_TOKEN_KEY, oktaAuth.getAccessToken()!, {
            //     expires: new Date(expireTime!),
            // });

            refetch()
        }
    }, [authState, oktaAuth, isLoggingOut, refetch]);

    useEffect(() => {
        if (tenantError) {
            // navigate("/login", { replace: true })
            setTenant(null)
            setUser("");
        } else if (tenantData) {
            setTenant(tenantData.data?.tenant!)
            setUser(tenantData.data?.user!);
        } else {
            // navigate("/login", { replace: true })
        }

    }, [tenantData, tenantError])

    useEffect(() => {
        if (body)
            mutate()
    }, [body, mutate])

    useEffect(() => {
        if (isSuccess) {
            const expireTime: number = +data?.data?.expiresAt!;
            const token: string = data?.data?.token!;

            localStorage.setItem("expires_in", expireTime + "");
            // localStorage.setItem("user", JSON.stringify(data?.data?.user))
            // console.log({ expireTime })
            setToken(token!)
            // Cookies.set(COOKIE_TOKEN_KEY, token!, {
            //     expires: new Date(expireTime!),
            // });
            setUser(data?.data?.user!);
            setBody(null)
            refetch()
            // eslint-disable-next-line react-hooks/exhaustive-deps
            navigate("/");
        }
        if (isError) {
            setErrorMsg(error?.response?.data?.message || "Login Failed")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isSuccess, isError, error, data, refetch])


    const logOut = () => {

        setTimeout(() => {
            if (authState?.isAuthenticated) {
                localStorage.removeItem("expires_in");
                // Cookies.remove(COOKIE_TOKEN_KEY)

                setLoggingOut(true)
                oktaAuth.signOut()
            }
            else {
                mutateLogout()
            }
        }, 1000);

    };

    useEffect(() => {
        if (logoutIsSuccess || logoutIsError) {
            setToken("")
            setUser("")
            localStorage.removeItem("expires_in");
            // Cookies.remove(COOKIE_TOKEN_KEY)

            // eslint-disable-next-line react-hooks/exhaustive-deps
            navigate("/login");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoutIsSuccess, logoutIsError])

    return (<AuthContext.Provider value={{
        token, user, loginAction, logOut, isLoading, errorMsg, ssoLogin, tenant
    }}>
        {tenantLoading ? <Spinner /> : children}
    </AuthContext.Provider >);
};

export default AuthProvider;

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context)
        throw new Error("Auth provider is not available")
    return context;
};