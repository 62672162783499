import { Box, ColumnConfig, DataTable, ResponsiveContext, Spinner, Text } from "grommet"
import { useContext, useState } from "react";
import { useGetOSCompliance } from "../api";
import { OSCompliance } from "../types";
import { StatusCritical, StatusGood, Tooltip, Next, CircleInformation, SubtractCircle, Aggregate } from "grommet-icons";
import { CardWithNumber } from "@/components/Card";
import { EmptyState } from "@/components/EmptyState";
import OsDetails from "./OsDetails";
import DoughnutChart from "@/components/DoughnutChart";
import moment from "moment";

function getIcon(status: string | undefined, forTiles: boolean): import("react").ReactNode {
    switch (status?.toLowerCase()) {
        case "failed": return <StatusCritical size={forTiles ? "1.3rem" : '1.1rem'} color={!forTiles ? "#FC6161" : "white"} />
        case "manual": return <CircleInformation size={forTiles ? "1.2rem" : '1.1rem'} color={!forTiles ? "#FFBC44" : "white"} />
        case "ignored": return <SubtractCircle size={forTiles ? "1.3rem" : '1.1rem'} color={!forTiles ? "#82FFF2" : "white"} />
        case "passed": case "success": return <StatusGood size={forTiles ? "1.3rem" : '1.1rem'} color={!forTiles ? "#17EBA0" : "white"} />
        case "total": return <Aggregate size={forTiles ? "1.3rem" : '1.1rem'} color={!forTiles ? "#7630EA" : "white"} />
        default:
            break;
    }
}

function getColor(status: string | undefined): string {
    switch (status?.toLowerCase()) {
        case "failed": return "#FC6161"
        case "manual": return "#FFBC44"
        case "ignored": return "#82FFF2"
        case "passed": case "success": return "#17EBA0"
        case "total": return "#7630EA"
        default: return "#CCCCCC"
    }
}

const columns: ColumnConfig<OSCompliance>[] = [

    {
        property: 'host',
        header: 'Host',
        verticalAlign: 'top',
        render: (datum: OSCompliance) => <Box align="start" >
            <Text >
                {datum.hostname}
            </Text>
            <Text color={'text-weak'}>{datum.ip_address}</Text>
        </Box>,
    },
    {
        property: 'linux_distro',
        header: 'Platform',
        verticalAlign: 'middle',
        render: (datum: OSCompliance) => <Box align="start" >
            <Text >
                {datum.linux_distro}
            </Text>
        </Box>,
    },
    {
        property: 'policy_group',
        header: 'Policy Group',
        verticalAlign: 'middle',
        render: (datum: OSCompliance) => <Box align="start" >
            <Text >
                {"CIS Benchmarks Level 1"}
            </Text>
        </Box>,
    },
    {
        property: '',
        header: 'Last Scanned',
        verticalAlign: 'middle',
        render: (datum: OSCompliance) => <Box align="start" >
            <Text >
                {datum?.data?.[0]?.last_scanned ? moment(datum?.data?.[0]?.last_scanned).format('DD MMM YYYY h:mm a') : "--"}
            </Text>
        </Box>,
    },
    {
        property: 'status',
        header: 'Status',
        verticalAlign: 'middle',
        render: (datum: any) => {
            const status = datum.statusCounts.failed > 0 ? "Failed" : "Success";
            return (
                <Box direction="row" justify="between" width={"auto"}>
                    <Box direction="row" align="center" gap={"xsmall"}>
                        {getIcon(status, false)}
                        <Text color={getColor(status)}>{status}</Text>
                    </Box>
                    <Next />
                </Box>)
        },
    }
];

const OperatingSystem = () => {
    const size = useContext(ResponsiveContext);
    const [selectedItem, selectItem] = useState<any>(null)
    const [filter, setFilter] = useState<string>("Total Nodes")

    const { isLoading, data } = useGetOSCompliance()

    function handleSelect(item: any): void {
        selectItem(item)
    }

    function handleSelectFilter(item: any): void {
        setFilter(item)
    }

    let _data = data?.data?.nodes || []
    if (filter && filter !== "Total Nodes")
        _data = _data?.filter((item: OSCompliance) => {
            return filter === "Success Nodes" ? item.statusCounts.failed === 0 : item.statusCounts.failed > 0
        })

    return (
        <Box margin={{ top: '1.5rem' }} gap={"medium"}>

            {selectedItem && <OsDetails data={selectedItem} onClose={() => selectItem(null)} getIcon={getIcon} getColor={getColor} />}

            <Box margin={{ vertical: 'small' }} justify="between" gap={"medium"} pad={{ horizontal: 'large' }} align="center" direction="row">
                <Box direction="row" justify="center" gap={"medium"} align="center">
                    <CardWithNumber selectedItem={filter} title={"Total Nodes"} value={data?.data?.totalNodes || 0} color={getColor("total")} onClick={handleSelectFilter} icon={getIcon("total", true)} />
                    <CardWithNumber selectedItem={filter} title={"Success Nodes"} value={data?.data?.successNodes || 0} color={getColor("passed")} onClick={handleSelectFilter} icon={getIcon("passed", true)} />
                    <CardWithNumber selectedItem={filter} title={"Failed Nodes"} value={data?.data?.failedNodes || 0} color={getColor("failed")} onClick={handleSelectFilter} icon={getIcon("failed", true)} />
                </Box>
                <Box height={"200px"}>
                    <DoughnutChart labels={["Success Nodes", "Failed Nodes"]} data={[data?.data?.successNodes || 0, data?.data?.failedNodes || 0]} colors={[getColor("passed"), getColor("failed")]} showLegend={false} showTitle={true} />
                </Box>
            </Box>

            <Box flex justify="center" align="center" fill>
                {isLoading ?
                    <Spinner />
                    :
                    _data?.length > 0 ?
                        <Box width={{ max: 'xxlarge' }} margin="auto" fill gap={{ row: 'small' }}>
                            <DataTable
                                aria-describedby="os-compliance"
                                key={selectedItem}
                                data={_data || []}
                                columns={columns}
                                onClickRow={({ datum }) => handleSelect(datum)}
                                fill
                                pad="small"
                                paginate={{
                                    border: 'top',
                                    direction: 'row',
                                    fill: 'horizontal',
                                    flex: false,
                                    justify: !['xsmall', 'small'].includes(size) ? 'end' : 'center',
                                }}
                                step={10}
                                sortable
                            />
                        </Box>
                        :
                        <Box margin={{ top: '5rem' }}>
                            <EmptyState align='center' level={"3"} title='No Data Available' description={""} icon={<Tooltip size='xlarge' />} />
                        </Box>
                }
            </Box>
        </Box>
    )
}

export default OperatingSystem