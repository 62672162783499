import { Box, Form, FormField, TextInput, Button, Text, CheckBox, ResponsiveContext, Spinner } from "grommet";
import { useContext, useState } from "react";
import { ILoginForm } from '@/auth/types'
import { CircleAlert, Next } from "grommet-icons";
import { COOKIE_REMEMBER_KEY, useAuth } from "@/auth/AuthProvider";
import Cookies from "js-cookie";
import { emailValidation } from "@/utils/appUtils";


const LoginForm = ({ onSubmit }: { onSubmit: (value: any) => void }) => {
    const loginCookie = Cookies.get(COOKIE_REMEMBER_KEY);
    const size = useContext(ResponsiveContext);
    const auth = useAuth();
    // const { authState, oktaAuth } = useOktaAuth();

    const loginData: ILoginForm = loginCookie ? JSON.parse(loginCookie) : null;

    const [formValues, setFormValues] = useState<ILoginForm>({
        user_name: loginData?.user_name || '',
        password: loginData?.password || '',
        rememberMe: loginData?.rememberMe || false,
    });

    const onChange = (values: ILoginForm) => {
        setFormValues(values);
    };

    // useEffect(() => {
    //     if (!authState || !authState.isAuthenticated) {
    //         // When user isn't authenticated, forget any user info
    //         console.log('user:', null);
    //     } else {
    //         oktaAuth.getUser().then((info) => {
    //             console.log('user:', info);
    //         }).catch((err) => {
    //             console.error(err);
    //         });
    //     }
    // }, [authState, oktaAuth]); // Update if authState changes

    return (
        <Box pad={{ horizontal: ["xsmall"].includes(size) ? '1.3rem' : '2rem', bottom: 'medium' }}>
            <Text weight={600} margin={{ bottom: '1.3rem' }} color={'dark-1'} size="2rem">
                Sign In
            </Text>
            <Form
                data-testid="login-form"
                validate="blur"
                value={formValues}
                messages={{
                    required: 'This is a required field.',
                }}
                autoComplete="off"
                onChange={nextValue => onChange(nextValue)}
                onSubmit={({ value }: { value: any }) => onSubmit(value)}
                method="post"
            >
                <FormField
                    label="Email"
                    htmlFor="email-sign-up"
                    name="user_name"
                    // margin={{ bottom: '0.5rem' }}
                    validate={emailValidation}
                    required={{ indicator: false }}
                >
                    <TextInput
                        id="email-sign-up"
                        name="user_name"
                        placeholder="Enter your email"
                    />
                </FormField>
                <FormField
                    label="Password"
                    htmlFor="password-sign-up"
                    name="password"
                    margin={{ bottom: '0.5rem' }}
                    required={{ indicator: false }}
                >
                    <TextInput
                        id="password-sign-up"
                        name="password"
                        placeholder="Enter your password"
                        type="password"
                    />
                </FormField>

                <FormField htmlFor="remember-me" name="rememberMe">
                    <CheckBox id="remember-me" name="rememberMe" label="Remember me" />
                </FormField>

                {auth.errorMsg && (
                    <Box
                        animation="fadeIn"
                        align="center"
                        background="validation-critical"
                        direction="row"
                        gap="xsmall"
                        margin={{ top: 'medium', bottom: 'medium' }}
                        pad="small"
                        round="4px"
                    >
                        <CircleAlert size="small" />
                        <Text size="xsmall">{auth.errorMsg}</Text>
                    </Box>
                )}

                <Box
                    // align={!['xsmall', 'small'].includes(size) ? 'start' : undefined}
                    margin={{ top: 'small', bottom: 'small' }}
                >
                    <Button label="Sign In" reverse disabled={auth.isLoading} primary type="submit" icon={auth.isLoading ? <Spinner size="small" /> : <Next />} />
                </Box>
            </Form>

            <Box margin={{ top: '1rem', bottom: '1.5rem' }} height={'1px'} background={"dark-6"} align="center">
                <span style={{ position: 'relative', padding: '0 15px', top: '-0.7rem', color: '#666666', background: '#ffffff', fontSize: '1rem' }}>OR</span>
            </Box>

            <Button secondary fill margin={'auto'} label="Sign in with SSO" onClick={auth.ssoLogin} />
        </Box>
    )
}

export default LoginForm